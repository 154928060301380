<div id="advanced_options_panel">
    <div class="table-responsive">
        <table class="table table-hover table-sm">
            <tbody>
                <tr>
                    <td class="propertyName">Drop Image Extensions</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="dropImageExtensions" />
                        <div class="hint">Whether to drop the extension on image names (eg. ".jpg", ".pcx").</div>
                    </td>
                </tr>
                <tr>
                    <td class="propertyName">Images Parent Name</td>
                    <td class="propertyValue"><input type="text" [(ngModel)]="imagesParentName" />
                        <div class="hint">Specifies parent entity name where images were imported</div>
                    </td>
                </tr>
                <tr>
                    <td class="propertyName">Layouts Parent Name</td>
                    <td class="propertyValue"><input type="text" [(ngModel)]="layoutsParentName" />
                        <div class="hint">Specifies parent entity name where layouts were/will be imported</div>
                    </td>
                </tr>
                <tr>
                    <td class="propertyName">Data Services Parent Name</td>
                    <td class="propertyValue"><input type="text" [(ngModel)]="dataServicesParentName" />
                        <div class="hint">Specifies parent entity name where data services referenced by LPS labels were/will be added/imported</div>
                    </td>
                </tr>

                <tr>
                    <td class="propertyName">Create Versions</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="createVersions" />
                        <div class="hint">If set, all versionable objects will get a default version</div>
                     </td>       
                </tr>

                <tr>
                    <td class="propertyName">Use Printer Number</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="usePrinterNumber" />
                        <div class="hint">If set, the printer number will be used as the printer name. The printer number will be left padded with zeros to a minimum of 3 digits</div>
                     </td>       
                </tr>


                <tr>
                    <td class="propertyName">Adjust Unsupported DPI</td>
                    <td class="propertyValue"><input type="checkbox" [(ngModel)]="adjustUnsupportedDpi" />
                        <div class="hint">If a DPI is not one of supported dpis, the dpi will be changed to the closest one.  Supported DPIs are [203,300,600,1200].</div>
                     </td>       
                </tr>

            </tbody>
        </table>
    </div>
</div>