import { Component, OnInit, Output } from '@angular/core';
import { LpsAdvancedOptions } from 'src/app/models/lps-advanced-options';

@Component({
  selector: 'advanced-options-panel',
  templateUrl: './advanced-options-panel.component.html',
  styleUrls: ['./advanced-options-panel.component.css']
})



export class AdvancedOptionsPanelComponent implements OnInit {

  public dropImageExtensions: boolean;
  public imagesParentName: string;
  public layoutsParentName: string;
  public dataServicesParentName: string;
  public createVersions: boolean;
  public usePrinterNumber: boolean;
  public adjustUnsupportedDpi: boolean;


  constructor() { }

  ngOnInit(): void {
    this.dropImageExtensions = false;
    this.imagesParentName = '0/Images/User Images';
    this.layoutsParentName = '0/Layouts';
    this.dataServicesParentName = '0/DataServices';
    this.createVersions = true;
    this.usePrinterNumber = false;
    this.adjustUnsupportedDpi = false;
  }


  public getAdvancedOptionSelections(): LpsAdvancedOptions {
    const advancedOptionSelections: LpsAdvancedOptions = {
      dropImageExtensions: this.dropImageExtensions,
      imagesParentName: this.imagesParentName,
      layoutsParentName: this.layoutsParentName,
      dataServicesParentName: this.dataServicesParentName,
      createVersions: this.createVersions,
      usePrinterNumber: this.usePrinterNumber,
      adjustUnsupportedDpi: this.adjustUnsupportedDpi,
    };
    return advancedOptionSelections;
  }

}